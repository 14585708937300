export const environment = {
  appUrl: 'https://staging.innovaticks.ch/',
  apiUrl: 'https://innovaticks-backend-staging-17d08e11d4aa.herokuapp.com/innovaticks/api',
  firebase: {
    apiKey: 'AIzaSyDUYd6sFRB-caxFMdcgNozX5iWh10TMkDw',
    authDomain: 'innovaticks.firebaseapp.com',
    projectId: 'innovaticks',
    storageBucket: 'innovaticks.appspot.com',
    messagingSenderId: '878297620439',
    appId: '1:878297620439:web:8785e956d0cd85bfd9f592'
  },
  adyenEnvironment: 'test',
  adyenClientKey: 'test_LHMDSDBRF5EUPLEVJ7WRGI43SAXZYNC2'
};
