import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService, NavigationUrlService } from '../../services';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html'
})
export class AuthHeaderComponent {
  protected loginRoute: string;
  protected homeRoute: string;

  constructor(
    private readonly urlService: NavigationUrlService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    if (this.router.url.startsWith('/admin')) {
      this.loginRoute = this.urlService.getAdminDashboardLoginPath();
      this.homeRoute = this.urlService.getAdminDashboardEventsPath();
    } else {
      this.loginRoute = this.urlService.getDashboardLoginPath();
      this.homeRoute = '/';
    }
  }

  protected navigate(event: Event, route: string) {
    event.preventDefault();
    this.navigationService.navigate(route);
  }
}
