import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, NavigationService, NavigationUrlService } from '../../../index';

@Component({
  selector: 'app-signup-email-confirmation',
  templateUrl: './signup-email-confirmation.component.html'
})
export class SignupEmailConfirmationComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const code = this.route.snapshot.queryParamMap.get('code')!;
    const basePath = this.router.url.startsWith('/admin') ? this.urlService.adminDashboardBasePath : this.urlService.websiteBasePath;
    this.authService.confirmUserAccountEmail$(code).subscribe({
      next: result => {
        if (result) {
          this.navigationService.navigate(this.urlService.getSignupEmailConfirmationSuccessfulPath(basePath));
        } else {
          this.navigationService.navigate(this.urlService.getSignupEmailConfirmationFailedPath(basePath));
        }
      },
      error: () => {
        this.navigationService.navigate(this.urlService.getSignupEmailConfirmationFailedPath(basePath));
      }
    });
  }
}
