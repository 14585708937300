import { Component, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavigationService } from '../../../services';

@Component({
  selector: 'app-object-detail-row',
  templateUrl: './object-detail-row.component.html'
})
export class ObjectDetailRowComponent {
  @Input()
  label = '';

  @Input()
  route?: string | undefined;

  @Input()
  extras?: NavigationExtras;

  @Input()
  link?: string | undefined;

  @Input()
  openInNewTab = false;

  @Input()
  content = '';

  @Input()
  infoText = '';

  constructor(private readonly navigation: NavigationService) {}

  navigate(event: Event) {
    event.preventDefault();
    if (this.route) {
      if (this.extras) {
        this.navigation.navigateWithExtras(this.route, this.extras);
      } else {
        this.navigation.navigate(this.route);
      }
    }
  }
}
