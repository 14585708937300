import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService, NavigationUrlService } from '../../../index';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html'
})
export class SignupConfirmationComponent implements OnInit {
  email!: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly router: Router
  ) {}

  onRequestNewConfirmationLink() {
    if (this.router.url.startsWith('/admin')) {
      this.navigationService.navigate(this.urlService.getRequestNewConfirmationLink(this.urlService.adminDashboardBasePath, this.email));
    } else {
      this.navigationService.navigate(this.urlService.getRequestNewConfirmationLink(this.urlService.websiteBasePath, this.email));
    }
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email')!;
  }
}
