export const DEFAULT_MAX_TICKETS_AMOUNT = 4;
export const DEFAULT_SELECTED_TICKETS = 1;
export const MAX_TICKETS_AMOUNT = 10;
export const MINIMAL_PASSWORD_LENGTH = 8;
export const PROPOSED_EVENTS_COUNT = 2;
export const FETCH_EVENTS_COUNT = 20;
export const DEFAULT_PAGE = 0;
export const DEFAULT_DATETIME_FORMAT = 'dd.MM.y HH:mm';
export const DEFAULT_DATE_FORMAT = 'dd.MM.y';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATE_FORMAT_FOR_FORM_INPUT = 'y-MM-dd';
export const DEFAULT_SECTION_ROWS = 20;
export const DEFAULT_SECTION_SEATS = 100;
export const DEFAULT_SECTION_SEATS_PER_ROW = 20;
export const LOW_AVAILABILITY_FOR_SECTION = 20;
export const TIMEOUT_AFTER_PAYMENT = 3000;
export const VAT_CH = 8.1;
export const RECEIPT_FONT_SIZE_SUBTITLE = 12;
export const RECEIPT_FONT_SIZE = 10;
export const RECEIPT_PADDING_LEFT = 20;
export const RECEIPT_PADDING_TOP = 5;
export const RECEIPT_NEW_PAGE_AFTER = 30;
export const INNOVATICKS_NAME = 'Innovaticks GmbH';
export const INNOVATICKS_ADDRESS = 'Landvogt-Waser-Strasse 17';
export const INNOVATICKS_ZIP = '8405 Winterthur';
export const HUNDRED_PERCENT = 100;
export const DEFAULT_AXIS_MULTIPLIER = 1.2;
export const CHART_COLORS = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#aec7e8',
  '#ffbb78',
  '#ff9896',
  '#c5b0d5',
  '#c49c94',
  '#f7b6d2',
  '#c7c7c7',
  '#dbdb8d',
  '#9edae5',
  '#98df8a'
];
export const MAX_DAYS_DELAY_FOR_PAYOUT = 5;
export const MAX_DAYS_DELAY_FOR_EVENT_BOOKING = 5;
export const MAX_DAYS_DELAY_VENDOR_BILL = 30;
export const MILLIS_TO_SECONDS_MULTIPLIER = 1000;
export const SECONDS_TO_HOURS_MULTIPLIER = 3600;
export const HOURS_TO_DAYS_MULTIPLIER = 24;
export const MILLIS_TO_DAYS_MULTIPLIER = MILLIS_TO_SECONDS_MULTIPLIER * SECONDS_TO_HOURS_MULTIPLIER * HOURS_TO_DAYS_MULTIPLIER;
export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];
export const MAX_FILE_SIZE = 1048576;
export const NO_FILE_SELECTED = 'NO_FILE_SELECTED';
export const CSV_CONTENT_TYPE = 'text/csv';
export const DEVICE_ID_SUBSTRING_LENGTH = 7;
export const SHOW_COPIED_INDICATOR_LENGTH = 3000;
