import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService, NavigationUrlService } from '../../../../index';

@Component({
  selector: 'app-change-password-confirmation',
  templateUrl: './change-password-confirmation.component.html'
})
export class ChangePasswordConfirmationComponent {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly router: Router
  ) {}

  onGoToLogin() {
    if (this.router.url.startsWith('/admin')) {
      this.navigationService.navigate(this.urlService.getAdminDashboardLoginPath());
    } else {
      this.navigationService.navigate(this.urlService.getDashboardLoginPath());
    }
  }
}
