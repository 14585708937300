import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService, NavigationUrlService } from '../../../../services';

@Component({
  selector: 'app-reset-password-failed',
  templateUrl: './reset-password-failed.component.html'
})
export class ResetPasswordFailedComponent {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly urlService: NavigationUrlService
  ) {}

  onResetPassword() {
    if (this.router.url.startsWith('/admin')) {
      this.navigationService.navigate(this.urlService.getAdminDashboardResetPasswordPath());
    } else {
      this.navigationService.navigate(this.urlService.getResetPasswordPath());
    }
  }
}
