import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  ConfirmPasswordResetRequestModel,
  NavigationService,
  NavigationUrlService,
  PASSWORD,
  PASSWORDS_NOT_MATCHING_VALUE
} from '../../../index';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  private passwordResetToken!: string;
  protected password?: string;
  protected passwordConfirm?: string;

  protected formValid = false;

  protected readonly PASSWORDS_NOT_MATCHING_VALUE = PASSWORDS_NOT_MATCHING_VALUE;
  protected readonly PASSWORD = PASSWORD;

  constructor(
    private readonly authService: AuthService,
    private readonly navigation: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  protected validateForm() {
    this.formValid = !!this.password && !!this.passwordConfirm && this.doPasswordsMatch();
  }

  protected doPasswordsMatch(): boolean {
    return this.password === undefined || this.passwordConfirm === undefined || this.password === this.passwordConfirm;
  }

  protected onSubmit() {
    if (this.formValid) {
      const requestData: ConfirmPasswordResetRequestModel = {
        passwordResetToken: this.passwordResetToken,
        newPassword: this.password!,
        newPasswordConfirm: this.passwordConfirm!
      };
      this.authService.confirmResetPassword$(requestData).subscribe({
        next: (result: boolean) => {
          if (result) {
            if (this.router.url.startsWith('/admin')) {
              this.navigation.navigate(this.urlService.getAdminDashboardChangePasswordConfirmationPath());
            } else {
              this.navigation.navigate(this.urlService.getDashboardChangePasswordConfirmationPath());
            }
          }
        },
        error: () => {
          if (this.router.url.startsWith('/admin')) {
            this.navigation.navigate(this.urlService.getAdminDashboardChangePasswordFailedPath());
          } else {
            this.navigation.navigate(this.urlService.getDashboardChangePasswordFailedPath());
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.passwordResetToken = this.route.snapshot.queryParamMap.get('token')!;
  }
}
