import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainHelperService {
  private readonly hostname: string;

  constructor() {
    this.hostname = window.location.hostname;
  }

  isCHDomain(): boolean {
    return this.hostname.endsWith('.ch');
  }

  isORGDomain(): boolean {
    return this.hostname.endsWith('.org');
  }
}
