import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  ADMIN_DASHBOARD,
  AuthService,
  BACKOFFICE,
  CUSTOMER_DASHBOARD,
  LocalStorageService,
  ModuleType,
  NavigationService,
  NavigationUrlService
} from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly navigation: NavigationService,
    private readonly navigationUrlService: NavigationUrlService,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  private getLoginRoute(module: ModuleType): string {
    let result = 'login';
    if (module === ADMIN_DASHBOARD) {
      result = this.navigationUrlService.getAdminDashboardLoginPath();
    } else if (module === CUSTOMER_DASHBOARD) {
      result = this.navigationUrlService.getDashboardLoginPath();
    } else if (module === BACKOFFICE) {
      result = this.navigationUrlService.getBackofficeLoginPath();
    }

    return result;
  }

  private checkTokenStillValid$(module: ModuleType): Observable<boolean> {
    let result$ = of(false);
    if (module === ADMIN_DASHBOARD) {
      result$ = this.authService.isAuthTokenStillValidForOrganizer$();
    } else if (module === CUSTOMER_DASHBOARD) {
      result$ = this.authService.isAuthTokenStillValidForUser$();
    } else if (module === BACKOFFICE) {
      result$ = this.authService.isAuthTokenStillValidForBackoffice$();
    }

    return result$;
  }

  private checkAuthentication$(module: ModuleType, loginRoute: string): Observable<boolean> {
    const authToken: string | undefined = this.localStorageService.getAuthenticationToken();

    if (authToken === undefined || authToken === '') {
      return this.authService.openLoginDialog$(module).pipe(
        switchMap((result: boolean) => {
          return of(result);
        })
      );
    }

    return this.checkTokenStillValid$(module).pipe(
      map((isValid: boolean) => {
        if (isValid) {
          return true;
        }

        return this.authService.openLoginDialog$(module).pipe(
          switchMap((result: boolean) => {
            return of(result);
          })
        );
      }),
      switchMap((result$: boolean | Observable<boolean>) => {
        if (typeof result$ === 'boolean') {
          return of(result$);
        }

        return result$;
      }),
      catchError(() => {
        this.navigation.navigate(loginRoute);

        return of(false);
      })
    );
  }

  isLoggedIn$(module: ModuleType) {
    return this.checkTokenStillValid$(module);
  }

  // eslint-disable-next-line rxjs/finnish
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const module = this.navigationUrlService.getModuleFromUrl(state.url);
    const loginRoute = this.getLoginRoute(module);

    return this.checkAuthentication$(module, loginRoute).pipe(
      tap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.navigation.navigate(loginRoute);
        }
      })
    );
  }
}
