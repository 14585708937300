import { Component, Input, OnInit } from '@angular/core';
import {
  AuthGuard,
  AuthService,
  CART,
  CUSTOMER_DASHBOARD,
  HAMBURGER,
  LOGIN,
  LOGOUT,
  NavigationService,
  NavigationUrlService,
  PROFILE,
  SEARCH,
  X_MARK
} from '../../../../shared';
import { CartService } from '../../../../shared/services/cart/cart-service';

export const HEADER_HEIGHTS_CLASSES = {
  SMALL: 'small',
  DEFAULT: 'default'
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  protected readonly HEADER_HEIGHTS_CLASSES = HEADER_HEIGHTS_CLASSES;
  protected readonly LOGIN = LOGIN;
  protected readonly LOGOUT = LOGOUT;
  protected readonly PROFILE = PROFILE;
  protected readonly CART = CART;
  protected readonly SEARCH = SEARCH;
  protected readonly HAMBURGER = HAMBURGER;
  protected readonly X_MARK = X_MARK;

  ticketCounter = 0;
  loginUrl = this.urlService.getDashboardLoginPath();
  profilePath = this.urlService.getDashboardProfilePath();
  cartRoute = '';

  @Input()
  showLoginButton = false;
  @Input()
  showSearchField = false;
  @Input()
  headerHeightClass = HEADER_HEIGHTS_CLASSES.DEFAULT;
  @Input()
  showBackgroundImage = true;

  isLoggedIn = false;
  searchInput = '';

  mobileMenuOpen = false;

  constructor(
    private readonly urlService: NavigationUrlService,
    private readonly authGuard: AuthGuard,
    private readonly authService: AuthService,
    private readonly navigation: NavigationService,
    private readonly cartService: CartService
  ) {
    this.cartRoute = urlService.getCartPath();
  }

  handleSearch() {
    this.navigation.navigateWithExtras('/home', {
      queryParams: { searchText: this.searchInput }
    });
  }

  logout(event: Event) {
    event.preventDefault();
    this.authService.logoutUser();
  }

  navigateToHome() {
    this.navigation.navigate('');
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  getCartCount() {
    this.cartService.getCart().subscribe(cart => {
      let counter = 0;
      if (cart) {
        for (const key of Object.keys(cart.orderEntriesByEvent)) {
          counter += (cart.orderEntriesByEvent[parseInt(key)] ?? []).length;
        }
      }
      this.ticketCounter = counter;
    });
  }

  navigate(route: string) {
    this.navigation.navigate(route);
  }

  ngOnInit() {
    this.authGuard.isLoggedIn$(CUSTOMER_DASHBOARD).subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    });
    this.getCartCount();
  }
}
