import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HELP, Icon } from '../../../const';
import { IconComponent } from '../../icon';
import { DocumentationLink } from '../../../../modules/admin_dashboard/consts/documentation-links';
import { DocumentationHelperService } from '../../../../modules/admin_dashboard/services/documentation/documentation-helper.service';

@Component({
  selector: 'app-h1',
  standalone: true,
  imports: [NgIf, IconComponent],
  templateUrl: './h1.component.html'
})
export class H1Component implements OnInit {
  protected readonly HELP = HELP;

  @Input({ required: true })
  title!: string;

  @Input()
  icon?: Icon;

  @Input()
  iconColorClass = 'text-black';

  @Input()
  hasIconClickedFunction = false;

  @Input()
  titleColor = 'black';

  @Output()
  iconClicked = new EventEmitter<boolean>();

  documentation?: DocumentationLink;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly doucmentationHelperService: DocumentationHelperService
  ) {}

  protected click() {
    this.iconClicked.emit(true);
  }

  protected getDocumentationLink() {
    if (this.documentation) {
      return this.doucmentationHelperService.getFullDocumentationLink(this.documentation);
    }

    return undefined;
  }

  ngOnInit(): void {
    if (this.route.snapshot.data.documentation) {
      this.documentation = this.route.snapshot.data.documentation as DocumentationLink;
    }
  }
}
