import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { filter } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LanguageService } from '../language';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private currentUrl = '';

  constructor(
    private readonly router: Router,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly languageService: LanguageService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setCurrentUrl();
    });
  }

  private setCurrentUrl(): void {
    this.currentUrl = this.router.url.replace(`/${this.localizeRouterService.parser.currentLang}`, '').split('?')[0]!;
  }

  private handleCaseThatShouldNeverHappen() {
    console.error('Should never happen.');
  }

  getCurrentUrl() {
    if (this.currentUrl && this.currentUrl !== '') {
      return this.currentUrl;
    }

    return this.router.url;
  }

  navigate(path: string) {
    this.navigateWithExtras(path);
  }

  navigateWithExtras(path: string, extras?: NavigationExtras) {
    let navPath: string | any[] = path;

    const languageFromUrl = this.languageService.getLanguageFromUrl(path);
    if (!languageFromUrl) {
      navPath = this.localizeRouterService.translateRoute(path);
    }
    this.router.navigate([navPath], extras).catch(() => this.handleCaseThatShouldNeverHappen());
  }
}
