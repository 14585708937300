import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionBarComponent } from '../../../widgets/action-bar/action-bar.component';
import { DialogBodyComponent } from '../../../widgets/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from '../../../widgets/dialog/dialog-footer/dialog-footer.component';
import { DialogWrapperComponent } from '../../../widgets/dialog/dialog-wrapper/dialog-wrapper.component';
import { IconButtonComponent } from '../../../widgets';
import { CHECK } from '../../../const';
import { AlertComponent } from '../../../widgets/alert/alert.component';

@Component({
  selector: 'app-generic-error-dialog',
  templateUrl: './generic-error-dialog.component.html',
  imports: [ActionBarComponent, DialogBodyComponent, DialogFooterComponent, DialogWrapperComponent, IconButtonComponent, AlertComponent],
  standalone: true
})
export class GenericErrorDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<GenericErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const GENERIC_ERROR_DIALOG_TYPE = GenericErrorDialogComponent;
