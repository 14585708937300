import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, NavigationService, NavigationUrlService } from '../../../../index';

@Component({
  selector: 'app-email-confirmation-successful',
  templateUrl: './email-confirmation-successful.component.html'
})
export class EmailConfirmationSuccessfulComponent implements OnInit {
  confirmMobileNumber = false;
  confirmationCode = '';

  deviceConfirmationSuccessful = false;
  deviceConfirmationFailed = false;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  onGoToLogin() {
    if (this.router.url.startsWith('/admin')) {
      this.navigationService.navigate(this.urlService.getAdminDashboardLoginPath());
    } else {
      this.navigationService.navigate(this.urlService.getDashboardLoginPath());
    }
  }

  confirmPhone() {
    if (!this.confirmationCode) {
      return;
    }
    this.authService.sendPhoneConfirmation$(this.confirmationCode).subscribe({
      next: result => {
        if (result) {
          this.deviceConfirmationFailed = false;
          this.deviceConfirmationSuccessful = true;
        } else {
          this.deviceConfirmationFailed = true;
          this.deviceConfirmationSuccessful = false;
        }
      },
      error: () => {
        this.deviceConfirmationFailed = true;
        this.deviceConfirmationSuccessful = false;
      }
    });
  }

  ngOnInit() {
    if (!this.router.url.startsWith('/admin')) {
      this.confirmMobileNumber = true;
    }
  }
}
