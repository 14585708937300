import { Injectable } from '@angular/core';
import { ADMIN_DASHBOARD, BACKOFFICE, CUSTOMER_DASHBOARD, ModuleType } from '../../const';

//TODO: Move this and api-url.service and url-service.ts to const folder and refactor

@Injectable({
  providedIn: 'root'
})
export class NavigationUrlService {
  private readonly dashboardRegex = /^(\/[a-z]{2})?\/dashboard/;
  private readonly adminRegex = /^(\/[a-z]{2})?\/admin/;
  private readonly backofficeRegex = /^(\/[a-z]{2})?\/backoffice/;

  readonly websiteBasePath = '/';
  readonly dashboardBasePath = `${this.websiteBasePath}dashboard/`;
  readonly adminDashboardBasePath = `${this.websiteBasePath}admin/`;
  readonly backofficeBasePath = `${this.websiteBasePath}backoffice/`;

  getModuleFromUrl(url: string): ModuleType {
    let result = CUSTOMER_DASHBOARD;
    if (url.match(this.adminRegex)) {
      result = ADMIN_DASHBOARD;
    } else if (url.match(this.dashboardRegex)) {
      result = CUSTOMER_DASHBOARD;
    } else if (url.match(this.backofficeRegex)) {
      result = BACKOFFICE;
    }

    return result as ModuleType;
  }

  ///WEBSITE///

  getCartPath(): string {
    return `${this.websiteBasePath}cart`;
  }

  getSignUpSuccessfulPath(email: string): string {
    return `${this.websiteBasePath}signup/successful/${email}`;
  }

  getResetPasswordPath(): string {
    return `${this.websiteBasePath}reset-password`;
  }

  ///CUSTOMER DASHBOARD///
  getDashboardBalancePath(): string {
    return `${this.dashboardBasePath}balance`;
  }

  getDashboardOrdersPath(): string {
    return `${this.dashboardBasePath}orders`;
  }

  getDashboardTicketsPath(): string {
    return `${this.dashboardBasePath}tickets`;
  }

  getDashboardProfilePath(): string {
    return `${this.dashboardBasePath}profile`;
  }

  getDashboardLoginPath(): string {
    return `${this.websiteBasePath}login`;
  }

  getDashboardSignUpPath(): string {
    return `${this.websiteBasePath}signup`;
  }

  getDashboardResetPasswordConfirmationPath(email: string): string {
    return `${this.websiteBasePath}reset-password/confirmation/${email}`;
  }

  getDashboardChangePasswordConfirmationPath(): string {
    return `${this.websiteBasePath}change-password/confirmation`;
  }

  getDashboardChangePasswordFailedPath(): string {
    return `${this.websiteBasePath}change-password/failed`;
  }

  getConfirmDevicePath(): string {
    return `${this.dashboardBasePath}confirm-device`;
  }

  ///ADMIN DASHBOARD///

  getAdminDashboardResetPasswordPath(): string {
    return `${this.adminDashboardBasePath}reset-password`;
  }

  getAdminDashboardResetPasswordConfirmationPath(email: string): string {
    return `${this.adminDashboardBasePath}reset-password/confirmation/${email}`;
  }

  getAdminDashboardChangePasswordConfirmationPath(): string {
    return `${this.adminDashboardBasePath}change-password/confirmation`;
  }

  getAdminDashboardChangePasswordFailedPath(): string {
    return `${this.adminDashboardBasePath}change-password/failed`;
  }

  getAdminDashboardSignUpSuccessfulPath(email: string): string {
    return `${this.adminDashboardBasePath}signup/successful/${email}`;
  }

  getRequestNewConfirmationLink(basePath: string, email?: string): string {
    if (email) {
      return `${basePath}signup/confirmation/resend/${email}`;
    }

    return `${basePath}signup/confirmation/resend`;
  }

  getAdminDashboardNewConfirmationLinkSendConfirmation(email: string, basePath: string): string {
    return `${basePath}signup/confirmation/resend/confirmation/${email}`;
  }

  getSignupEmailConfirmationSuccessfulPath(basePath: string): string {
    return `${basePath}signup/email-confirmation/successful`;
  }

  getSignupEmailConfirmationFailedPath(basePath: string): string {
    return `${basePath}signup/email-confirmation/failed`;
  }

  getAdminDashboardEventsPath(): string {
    return `${this.adminDashboardBasePath}events`;
  }

  getAdminDashboardLayoutsPath(): string {
    return `${this.adminDashboardBasePath}layouts`;
  }

  getAdminDashboardPricesPath(): string {
    return `${this.adminDashboardBasePath}prices`;
  }

  getAdminDashboardStatisticsPath(): string {
    return `${this.adminDashboardBasePath}statistics`;
  }

  getAdminDashboardPayoutsPath(): string {
    return `${this.adminDashboardBasePath}payouts`;
  }

  getAdminDashboardBillsPath(): string {
    return `${this.adminDashboardBasePath}bills`;
  }

  getAdminDashboardUsersPath(): string {
    return `${this.adminDashboardBasePath}users`;
  }

  getAdminDashboardOrganizerPath(): string {
    return `${this.adminDashboardBasePath}organizer`;
  }

  getAdminDashboardProfilePath(): string {
    return `${this.adminDashboardBasePath}profile`;
  }

  getAdminDashboardLoginPath(): string {
    return `${this.adminDashboardBasePath}login`;
  }

  getAdminDashboardSignUpPath(): string {
    return `${this.adminDashboardBasePath}signup`;
  }

  getAdminInvitationConfirmationSuccessfulPath(): string {
    return `${this.adminDashboardBasePath}invitation/confirmation/successful`;
  }

  getCreateEventPath(): string {
    return `${this.adminDashboardBasePath}event/create/`;
  }

  getCreateEventGroupPath(): string {
    return `${this.adminDashboardBasePath}event-group/create/`;
  }

  getAdminDashboardLocationsPath(): string {
    return `${this.adminDashboardBasePath}locations`;
  }

  getLocationDetailPath(): string {
    return `${this.adminDashboardBasePath}location/`;
  }

  getCreateLocationPath(): string {
    return `${this.adminDashboardBasePath}location/create/`;
  }

  getEditLocationPath(): string {
    return `${this.adminDashboardBasePath}location/edit/`;
  }

  getCreateLayoutPath(): string {
    return `${this.adminDashboardBasePath}layout/create/`;
  }

  getEditSimpleSectionConfigPath(): string {
    return `${this.adminDashboardBasePath}simplesectionconfig/edit/`;
  }

  getEditSeatMapPath(): string {
    return `${this.adminDashboardBasePath}seatmap/edit/`;
  }

  getSimpleSectionConfigDetailPath(): string {
    return `${this.adminDashboardBasePath}simplesectionconfig/`;
  }

  getSeatMapDetailPath(): string {
    return `${this.adminDashboardBasePath}seatmap/`;
  }

  getPriceConfigDetailPath(): string {
    return `${this.adminDashboardBasePath}price-config/`;
  }

  getCreatePriceConfigPath(): string {
    return `${this.adminDashboardBasePath}price-config/create/`;
  }

  getEditPriceConfigPath(): string {
    return `${this.adminDashboardBasePath}price-config/edit/`;
  }

  getEventGroupDetailPath(): string {
    return `${this.adminDashboardBasePath}event-group/`;
  }

  getEditEventGroupPath(): string {
    return `${this.adminDashboardBasePath}event-group/edit/`;
  }

  getEventGroupStatisticsPath(): string {
    return `${this.adminDashboardBasePath}/statistics/eventGroup/`;
  }

  getEventStatisticsPath(): string {
    return `${this.adminDashboardBasePath}/statistics/event/`;
  }

  getEventDetailPath(): string {
    return `${this.adminDashboardBasePath}event/`;
  }

  getEditEventPath(): string {
    return `${this.adminDashboardBasePath}event/edit/`;
  }

  getInviteNewUserPath(): string {
    return `${this.getAdminDashboardUsersPath()}/invitation/create`;
  }

  getEditUserInvitationPath(invitationId: number): string {
    return `${this.getAdminDashboardUsersPath()}/invitation/edit/${invitationId}`;
  }

  //BACKOFFICE
  getBackofficeLoginPath(): string {
    return `${this.backofficeBasePath}login`;
  }
}
