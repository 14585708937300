import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  static readonly AUTHENTICATION_TOKEN_STORAGE_KEY = 'authToken';
  static readonly CART_ID_STORAGE_KEY = 'cartId';

  private getItemAsString(key: string) {
    const item = localStorage.getItem(key);

    return item ? item : undefined;
  }

  getAuthenticationToken(): string | undefined {
    return this.getItemAsString(LocalStorageService.AUTHENTICATION_TOKEN_STORAGE_KEY);
  }

  storeAuthenticationToken(authToken: string) {
    localStorage.setItem(LocalStorageService.AUTHENTICATION_TOKEN_STORAGE_KEY, authToken);
  }

  storeCartId(cartId: string) {
    localStorage.setItem(LocalStorageService.CART_ID_STORAGE_KEY, cartId.toString());
  }

  getCartId() {
    return this.getItemAsString(LocalStorageService.CART_ID_STORAGE_KEY);
  }

  removeAuthenticationToken() {
    localStorage.removeItem(LocalStorageService.AUTHENTICATION_TOKEN_STORAGE_KEY);
  }

  removeCartSessionId() {
    localStorage.removeItem(LocalStorageService.CART_ID_STORAGE_KEY);
  }
}
