<div class="flex grow">
  <div class="w-1/4 p-2 text-lg">
    <div class="font-bold">{{ label }}</div>
  </div>
  <div class="w-1/4 p-2 text-lg" *ngIf="!route && !link">
    {{ content }}
  </div>
  <div class="w-1/4 p-2 text-lg" *ngIf="route">
    <a class="text-button-blue-dark hover:cursor-pointer" href="#" (click)="navigate($event)">{{ content }}</a>
  </div>
  <div class="w-1/4 p-2 text-lg" *ngIf="link">
    <a class="text-button-blue-dark hover:cursor-pointer" href="{{ link }}" [target]="openInNewTab ? '_blank' : '_self'">{{ content }}</a>
  </div>
  <div class="w-2/4 p-2 text-lg">
    {{ infoText }}
  </div>
</div>
