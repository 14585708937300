import { Injectable } from '@angular/core';
import { DOCUMENTATION_FOLDER, DocumentationLink, INNOVATICKS_DOCUMENTATION_BASE_LINK } from '../../consts/documentation-links';
import { LanguageService } from '../../../../shared';
import { DomainHelperService } from '../../../../shared/services/domain/domain-helper-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentationHelperService {
  constructor(
    private readonly languageService: LanguageService,
    private readonly domainHelperService: DomainHelperService
  ) {}

  getLanguage() {
    return this.languageService.getCurrentLanguageKey();
  }

  getLandingPageBaseLink(): string {
    if (this.domainHelperService.isORGDomain()) {
      return `${INNOVATICKS_DOCUMENTATION_BASE_LINK}.org`;
    }
    if (this.domainHelperService.isCHDomain()) {
      return `${INNOVATICKS_DOCUMENTATION_BASE_LINK}.ch`;
    }

    console.error('Could not determine environment location');

    return `${INNOVATICKS_DOCUMENTATION_BASE_LINK}.org`;
  }

  getFullDocumentationLink(documentationLink: DocumentationLink) {
    return `${this.getLandingPageBaseLink()}${DOCUMENTATION_FOLDER}/${this.getLanguage().toLowerCase()}/${documentationLink}`;
  }
}
