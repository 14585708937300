import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { LocalStorageService } from '../../local-storage';

@Injectable({
  providedIn: 'root'
})
export class RequestAuthInterceptorService implements HttpInterceptor {
  private authToken: string | undefined;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly tokenExtractor: HttpXsrfTokenExtractor
  ) {}

  setAuthToken() {
    this.authToken = this.localStorageService.getAuthenticationToken();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.setAuthToken();
    // always withCredentials needed for csrf
    let newRequest: HttpRequest<unknown> = request.clone({ withCredentials: true });
    // auth authToken if needed
    if (this.authToken && this.isInternalRequest(request.url)) {
      newRequest = newRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authToken}`
        }
      });
    }
    // add csrfToken if needed
    const csrfToken = this.tokenExtractor.getToken() ?? undefined;
    console.log('Request', newRequest, csrfToken);
    if (csrfToken && !newRequest.headers.has('X-XSRF-TOKEN')) {
      newRequest = newRequest.clone({
        setHeaders: {
          'X-XSRF-TOKEN': csrfToken
        }
      });
    }

    if (newRequest !== request) {
      console.log('Sending new Request', newRequest);

      return next.handle(newRequest).pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            const newToken = event.headers.get('Authorization');
            if (newToken) {
              this.localStorageService.storeAuthenticationToken(newToken);
            }
          }

          return event;
        })
      );
    }
    console.log('Sending Request', request);

    return next.handle(request);
  }

  isInternalRequest(url: string): boolean {
    return url.includes('api/internal') || url.includes('api/admin') || url.includes('api/backoffice');
  }
}
