import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService, NavigationUrlService } from '../../../../index';

@Component({
  selector: 'app-email-confirmation-failed',
  templateUrl: './email-confirmation-failed.component.html'
})
export class EmailConfirmationFailedComponent {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly router: Router
  ) {}

  onRequestNewConfirmationLink() {
    const basePath = this.router.url.startsWith('/admin') ? this.urlService.adminDashboardBasePath : this.urlService.websiteBasePath;
    this.navigationService.navigate(this.urlService.getRequestNewConfirmationLink(basePath));
  }
}
