import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionBarComponent } from '../../../widgets/action-bar/action-bar.component';
import { DialogBodyComponent } from '../../../widgets/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from '../../../widgets/dialog/dialog-footer/dialog-footer.component';
import { DialogWrapperComponent } from '../../../widgets/dialog/dialog-wrapper/dialog-wrapper.component';
import { IconButtonComponent } from '../../../widgets';
import { CHECK } from '../../../const';
import { AlertComponent } from '../../../widgets/alert/alert.component';

@Component({
  selector: 'app-server-error-dialog',
  templateUrl: './server-error-dialog.component.html',
  imports: [ActionBarComponent, DialogBodyComponent, DialogFooterComponent, DialogWrapperComponent, IconButtonComponent, AlertComponent],
  standalone: true
})
export class ServerErrorDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<ServerErrorDialogComponent>,
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const INTERNAL_SERVER_ERROR_DIALOG_TYPE = ServerErrorDialogComponent;
