import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, EMAIL, NavigationService, NavigationUrlService } from '../../../index';

@Component({
  selector: 'app-resend-confirmation-link',
  templateUrl: './resend-confirmation-link.component.html'
})
export class ResendConfirmationLinkComponent implements OnInit {
  protected readonly EMAIL = EMAIL;

  email?: string;
  formValid = false;

  showActiveError = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly urlService: NavigationUrlService,
    private readonly router: Router
  ) {}

  onEmailChange() {
    this.formValid = !!this.email;
  }

  onResendConfirmationLink() {
    if (this.email) {
      const basePath = this.router.url.startsWith('/admin') ? this.urlService.adminDashboardBasePath : this.urlService.websiteBasePath;

      this.authService.resendConfirmationLink$(this.email).subscribe({
        next: () =>
          this.navigationService.navigate(this.urlService.getAdminDashboardNewConfirmationLinkSendConfirmation(this.email!, basePath)),
        error: () =>
          this.navigationService.navigate(this.urlService.getAdminDashboardNewConfirmationLinkSendConfirmation(this.email!, basePath))
      });
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.showActiveError) {
        this.showActiveError = true;
      }
    });
    const emailParam = this.route.snapshot.paramMap.get('email');
    if (emailParam) {
      this.email = emailParam;
      this.onEmailChange();
    }
  }
}
